<template>
  <div>
    <TblInformationBySchool />
  </div>
</template>

<script>
import TblScreeningLogBySchool from "./components/TblScreeningLogBySchool";
import TblInformationBySchool from "./components/TblInformationBySchool";
import SltAmphur from "./components/SltAmphur";

export default {
  name: "Dashboard",
  components: {
    TblScreeningLogBySchool,
    TblInformationBySchool,
    SltAmphur,
  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
    };
  },
  methods: {},
  mounted() {
    //console.log(this.userData);
  },
};
</script>
