<template>
  <CCol lg="12">
    <CCard>
      <CCardHeader class="bg-secondary">สารสนเทศโรงเรียน </CCardHeader>

      <CCardBody v-if="rows">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'information_id', type: 'desc' },
          }"
          :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
        >
          >
          <div slot="table-actions">
            <button
              class="btn btn-yellow"
              style="margin-right: 10px"
              @click="modalCreate = true"
            >
              +add
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'information_word'">
              <div v-if="props.row.information_word">
                <a
                  :href="$hostUrl + props.row.information_word.slice(2)"
                  target="_blank"
                  class="btn btn-primary"
                  style="margin-top: 5px"
                  >.docx</a
                >
              </div>
              <div v-if="props.row.information_pdf">
                <a
                  :href="$hostUrl + props.row.information_pdf.slice(2)"
                  target="_blank"
                  class="btn btn-danger"
                  style="margin-top: 5px"
                  >.pdf</a
                >
              </div>
            </span>
            <span v-else-if="props.column.field == 'actions'">
              <div v-if="userData.user_id == props.row.user_id">
                <button
                  class="btn btn-warning btn-block"
                  type="button"
                  @click="openModal(props.row)"
                >
                  edit
                </button>
                <button
                  class="btn btn-danger btn-block"
                  type="button"
                  @click="deleteInformation(props.row.information_id)"
                >
                  delete
                </button>
              </div>
            </span>
          </template>
        </vue-good-table>
      </CCardBody>
      <CCardFooter class="text-right"> </CCardFooter>
    </CCard>

    <!--MODAL CREATE---------------------------------------------------------------------------->
    <form
      id="formCreateInfomation"
      v-on:submit.prevent="createInfomation"
      method="POST"
    >
      <CModal
        :show.sync="modalCreate"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <!--body-->

          <CCol lg="6">
            <SltAcadYear />
          </CCol>

          <CCol lg="6">
            <SltAspect />
          </CCol>
          <CCol lg="12" style="margin-top: 20px">
            <label for="formFile" class="form-label">ไฟล์ .docx</label>
            <input
              class="form-control"
              type="file"
              name="fileWord"
              id="formFile"
              accept=".docx"
              required
            />
          </CCol>
          <CCol lg="12" style="margin-top: 20px">
            <label for="formFile" class="form-label">ไฟล์ .pdf</label>
            <input
              class="form-control"
              name="filePDF"
              type="file"
              id="formFile"
              accept=".pdf"
              required
            />
          </CCol>
          <CCol lg="12" style="margin-top: 20px">
            <label for="formFile" class="form-label">ไฟล์ .rtf</label>
            <input
              class="form-control"
              name="fileRTF"
              type="file"
              id="formFile"
              accept=".rtf"
            />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">เพิ่มข้อมูลสารสนเทศ:</h3>
          </h6>
          <CButtonClose @click="modalCreate = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalCreate = false" color="black">Discard</CButton>

          <button
            type="submit"
            class="btn btn-yellow"
            :disabled="btnControl.disable"
          >
            <span>{{ btnControl.btnText }}</span>
          </button>
          <div v-if="btnControl.disable == true">
            <div class="spinner-border text-info" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </template>
      </CModal>
    </form>
    <!--MODAL EDIT---------------------------------------------------------------------------->
    <form
      id="formEditInfomation"
      v-on:submit.prevent="updateInformation"
      method="POST"
    >
      <CModal
        :show.sync="modalEdit"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="danger"
      >
        <CRow v-if="selectedInformation">
          <!--body-->

          <CCol lg="6">
            <strong>#:</strong>
            {{ selectedInformation.information_id }}
          </CCol>
          <CCol lg="6">
            <strong>ปีการศึกษา:</strong>
            {{ selectedInformation.information_acad_year }}
          </CCol>
          <CCol lg="6">
            <strong>ด้าน:</strong>
            {{ selectedInformation.aspect_name }}
          </CCol>

          <CCol lg="12" style="margin-top: 20px">
            <label for="formFile" class="form-label">ไฟล์ .docx (ใหม่)</label>
            <input
              class="form-control"
              type="file"
              name="fileWord"
              id="formFile"
              accept=".docx"
              required
            />
          </CCol>
          <CCol lg="12" style="margin-top: 20px">
            <label for="formFile" class="form-label">ไฟล์ .pdf (ใหม่)</label>
            <input
              class="form-control"
              name="filePDF"
              type="file"
              id="formFile"
              accept=".pdf"
              required
            />
          </CCol>
          <CCol lg="12" style="margin-top: 20px">
            <label for="formFile" class="form-label">ไฟล์ .rtf (ใหม่)</label>
            <input
              class="form-control"
              name="fileRTF"
              type="file"
              id="formFile"
              accept=".rtf"
            />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">แก้ไขข้อมูลสารสนเทศ:</h3>
          </h6>
          <CButtonClose @click="modalEdit = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalEdit = false" color="black">Discard</CButton>

          <button
            type="submit"
            class="btn btn-yellow"
            :disabled="btnControl.disable"
          >
            <span>{{ btnControl.btnText }}</span>
          </button>
          <div v-if="btnControl.disable == true">
            <div class="spinner-border text-info" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </template>
      </CModal>
    </form>
  </CCol>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import SltAcadYear from "./SltAcadYear";
import SltAspect from "./SltAspect";

export default {
  components: {
    Datepicker,
    vSelect,
    VueGoodTable,
    SltAcadYear,
    SltAspect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      modalSuccess: null,
      newCiteria: null,
      modalCreate: false,
      modalEdit: false,

      rows: null,
      columns: [
        {
          label: "#",
          field: "information_id",
          type: "number",
        },
        {
          label: "ปีการศึกษา",
          field: "information_acad_year",
          type: "number",
        },

        {
          label: "ด้าน",
          field: "aspect_name",
        },
        {
          label: "ไฟล์",
          field: "information_word",
          type: "tect",
        },
        {
          label: "แก้ไขเมื่อ",
          field: "information_updated",
        },
        {
          label: "แก้ไขโดย",
          field: "updaterName",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      sumAmount: null,
      uploadFiles: [],
      activeTab: 0,
      enableUpload: false,
      informationInfo: null,
      btnControl: { disable: false, btnText: "ส่ง" },
      selectedInformation: null,
    };
  },
  mounted() {
    //console.log(this.userData);
    //FETCH INFORMATION INFO BY SCHOOL
    let formData = new FormData();
    formData.append("txtAction", "getAllInformation");
    formData.append("schoolCode", this.userData.user_address);
    this.axios
      .post(this.$hostUrl + "php_action/informationAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
      });
  },
  methods: {
    createInfomation(event) {
      if (
        !this.$store.state.selectedAspect ||
        this.$store.state.selectedAspect == ""
      ) {
        alert("กรุณาระบุด้าน");
        return false;
      }

      this.btnControl = { disable: true, btnText: "sending..." };

      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("acadYear", this.$store.state.selectedAcadYear.code);
      formData.append("aspectId", this.$store.state.selectedAspect.code);
      formData.append("userId", this.userData.user_id);
      this.axios
        .post(this.$hostUrl + "php_action/informationAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "created") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
            this.btnControl = { disable: false, btnText: "ส่ง" };
          }
        });
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openModal(data) {
      this.modalEdit = true;
      this.selectedInformation = data;
    },
    updateInformation(event) {
      this.btnControl = { disable: true, btnText: "sending..." };
      const form = document.getElementById(event.target.id);
      let formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("userId", this.userData.user_id);
      formData.append("informationId", this.selectedInformation.information_id);

      this.axios
        .post(this.$hostUrl + "php_action/informationAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "updated") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
            this.btnControl = { disable: false, btnText: "ส่ง" };
          }
        });
    },
    closeModal() {
      location.reload();
    },

    handleImages(files) {
      this.uploadFiles = files;
      //console.log(this.uploadFiles);
    },
    deleteInformation(id) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        let formData = new FormData();
        formData.append('txtAction', 'delete');
        formData.append('userId', this.userData.user_id);
        formData.append('informationId', id);
        this.axios
          .post(
            this.$hostUrl +
              "php_action/informationAPI.php", formData
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == 'deleted') {
              alert('ลบรายการสำเร็จ');
              location.reload();
            } else {
              alert(response.data.error);
            }
          });

      } else {
        return true;
      }

    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
